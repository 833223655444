import { WebSdkModel } from 'packages/embedded-web-sdk';
export interface ExternalLinkOpenerPluginCreator {
  (exec: WebSdkModel['exec']): ExternalLinkOpenerPlugin;
}

export interface WebExternalLinkOpenerPluginCreator {
  (): ExternalLinkOpenerPlugin;
}

export interface ExternalLinkOpenerPlugin {
  /**
   * Call with a url to open a url. This will be passed directly to the OS level
   * url opener without modification:
   *
   * * iOS: `UIApplication.shared.open(URL(string: url, options: [:]))`
   * * Android: `startActivity(Intent(Intent.ACTION_VIEW, Uri.parse(urlString)))`
   * * Cordova: `window.open(url,"_system", options)` which is equalvalent to `cordova.InAppBrowser.open(url,"_system", options)`
   * * Web: `window.open(url, ,"_blank",options)`
   */
  openExternalLink(url: string): Promise<void>;
}

export const createExternalLinkOpenerPlugin: ExternalLinkOpenerPluginCreator = (
  exec
) => ({
  async openExternalLink(url) {
    const didOpen = await exec({
      plugin: 'externalLinkHandler',
      action: 'open',
      params: { url }
    });
    if (!didOpen) {
      throw new Error(`unable to open url ${url}`);
    }
  }
});

export const createWebExternalLinkOpenerPlugin: WebExternalLinkOpenerPluginCreator = () => ({
  async openExternalLink(url) {
    (window as any).cordova
      ? (window as any).cordova.InAppBrowser.open(url, '_system', 'noopener')
      : window.open(url, '_blank', 'noopener');
  }
});

// TODO better name?
export const createWebOnlyExternalLinkOpenerPlugin: WebExternalLinkOpenerPluginCreator = () => ({
  async openExternalLink(url) {
    window.open(url, '_blank', 'noopener');
  }
});
